import {exportCsv, exportPdf} from "../dao";

function handleCsvExport(target_url, fileName, setExporting) {
  setExporting(true);
  exportCsv(target_url)
    .then(response => {
      setExporting(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const link = document.createElement('a');
      const blobUrl = window.URL.createObjectURL(blob);
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

function handlePdfExport(target_url, fileName, setExporting) {
  setExporting(true);
  exportPdf(target_url)
    .then(response => {
      setExporting(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const link = document.createElement('a');
      const blobUrl = window.URL.createObjectURL(blob);
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export {handleCsvExport, handlePdfExport};