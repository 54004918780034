const themeConfig = {
  TAP: {
    Theme: 'TAP',
    ThemeColorPrimary: '#198754',
    ThemeColorSecondary: '#c16d18',
    ApiUrl: 'https://api.tapis.tretra.org/',
    SystemStartYear: 2024,
    SystemStartYearCalendar: 2024,
    SystemShortName: 'Tapis',
    SystemDescription: 'Tretrácký informační systém',
    AdminEmail: 'tapis@tretra.org',
    HomeClub: "TAP",
    HomeClubName: "KOB Tretra Praha",
    HomeClubBankAccount: "2802097915/2010",
    TabTitle: 'Tapis – Tretrácký informační systém',
    MetaDescription: 'Informační systém KOB Tretra Praha',

    homeSection: 'Domů',
    clubSection: 'Klub',
    cupsSection: 'Žebříčky',
    myRacesSection: 'Mé závody',

    LinkEntryLocalCreate: 'Přihlásit se',
    LinkEntryRemoteCreate: 'Přihlásit se',
    LinkEntryPreliminaryCreate: 'Přihlásit se',
    LinkEntryEdit: 'Upravit přihlášku',
    LinkEntryOthersLocal: 'Přihlásit ostatní',
    LinkEntryOthersRemote: 'Přihlásit ostatní',
    LinkServices: 'Služby',
    LinkOffers: 'Nabídky',
    LinkClubRanking: 'Klubový ranking',

    ButtonEntryUpdate: 'Upravit přihlášku',
    ButtonEventUpdate: 'Upravit infomace',

    transportHandleSwitch: 'Zveřejnit uživatelům',
    transportHandleTooltip: 'Pokud není rozdělení zveřejněno, pak ho běžní uživatelé nevidí',

    CondClubPageTableLinks: '1',
    CondClubAdminPageTableLinks: '1',
    CondSyncDataToOris: '1',
    CondMyTransportSelf: '',
    CondEventDining: '',
    CondEventAccommodation: '1',
    CondApplyToOris: '1',
    CondEventShowDNS: '1',
    CondShowUserBilling: '1',
    CondElemTableHover: '1',
    CondMyRacesOnNav: '1',

    usernamePlaceholder: 'Tretrak',
  },
  PGP: {
    Theme: 'PGP',
    ThemeColorPrimary: '#2C438B',
    ThemeColorSecondary: '#F04E37',
    ApiUrl: 'https://api.pragis.sk-praga.cz/',
    SystemStartYear: 2025,
    SystemStartYearCalendar: 2024,
    SystemShortName: 'PragIS',
    SystemDescription: 'Pragovácký informační systém',
    AdminEmail: 'syon@centrum.cz',
    HomeClub: "PGP",
    HomeClubName: "SK Praga",
    HomeClubBankAccount: "???",
    TabTitle: 'PragIS – Pragovácký informační systém',
    MetaDescription: 'Přihlášení do PragISu',

    homeSection: 'Kalendář akcí',
    clubSection: 'Seznam členů',
    cupsSection: '',
    myRacesSection: 'Moje závody',

    LinkEntryLocalCreate: 'Přihlásit se',
    LinkEntryRemoteCreate: '',
    LinkEntryPreliminaryCreate: '',
    LinkEntryEdit: 'Moje přihláška',
    LinkEntryOthersLocal: 'Přihlásit ostatní',
    LinkEntryOthersRemote: '',
    LinkServices: '',
    LinkOffers: 'Nabídky',
    LinkClubRanking: 'Klubový ranking',

    ButtonEntryUpdate: 'Uložit změny',
    ButtonEventUpdate: 'Uložit změny',

    transportHandleSwitch: 'Uzamknout úpravy',
    transportHandleTooltip: 'Uživatelé si již nebudou moci sami měnit složení aut',

    CondClubPageTableLinks: '',
    CondClubAdminPageTableLinks: '',
    CondSyncDataToOris: '',
    CondMyTransportSelf: '1',
    CondEventDining: '',
    CondEventAccommodation: '',
    CondApplyToOris: '',
    CondEventShowDNS: '',
    CondShowUserBilling: '',
    CondElemTableHover: '',
    CondMyRacesOnNav: '',

    usernamePlaceholder: 'PGP0000',
  },
};

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts[0]; // Třetí řád domény
  }
  return null;
};

const getTheme = () => {
  switch (getSubdomain()) {
    case 'tapis':
      return 'TAP'
    case 'pragis':
      return 'PGP'
    default:
      return 'TAP'
  }
}

const themeVariant = getTheme();
const ThemeCount = Object.keys(themeConfig).length;
const currentTheme = themeConfig[themeVariant].Theme;
const ApiUrl = (process.env.NODE_ENV === 'production' ? themeConfig[themeVariant].ApiUrl : 'http://localhost:8000/');
export {currentTheme, ApiUrl, ThemeCount};
export const labels = themeConfig[themeVariant];
