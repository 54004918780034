import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import {getHome, getServerInfo, getUserInfo} from "../../dao";
import React, { useState, useEffect } from 'react';
import {
  handleErrorLoading, parseDate, parseDateWithoutYear, selectEnrollVariant, setBrowserTabText, HomeYearOptions
} from "../../helpers/Functions"
import useUserData from "../../helpers/Auth";
import {faRotate, faInfo, faCheckDouble, faCheck, faXmark, faPencil} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCache from "../../helpers/FilterCache";
import TermIndicator from "../../components/visual/TermIndicator";
import ErrorAlertFullscreen from "../../components/overlays/ErrorAlertFullscreen";
import {
  EventTypeOptions,
  MonthOptionsExtendedCurrent,
  RegionOptions,
  SportTypeOptions,
  DisciplineAllOptions,
  MobileWidthLimitSM,
  HomeClub,
  ORIS_URL,
  CancelledOptions,
  Theme,
  EventTypeOptionsAlt,
  MonthOptionsExtended
} from "../../helpers/Constants";
import PageContainer from "../../layout/PageContainer";
import LoadingIcon from "../../components/overlays/LoadingIcon";
import LevelIndicator from "../../components/visual/LevelIndicator";
import FormSelectArray from "../../components/form/FormSelectArray";
import FormSelectDict from "../../components/form/FormSelectDict";
import FormCheckbox from "../../components/form/FormCheckbox";
import {selectIconClub, selectLogoOris} from "../../components/visual/LogosIcons";
import {labels} from "../../themeLabels";


const Home = () => {
  const [loading, setLoading] = useState(true);
  const [errorRemote, setErrorRemote] = useState("");
  const [spinResetIcon, setSpinResetIcon] = useState(false);
  const [settingsView, setSettingsView] = useState(false);
  const [data, setData] = useState({});
  const [serverInfo, setServerInfo] = useState({});
  const {userData, setUserData} = useUserData();

  const {
    month, setMonth, year, setYear, type, setType, region, setRegion,
    sport, setSport, cancelled, setCancelled
  } = useCache();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= MobileWidthLimitSM);

  useEffect(() => {
    const handleOrientationChange = () => {setIsMobile(window.innerWidth <= MobileWidthLimitSM)};
    window.addEventListener('resize', handleOrientationChange);
    return () => {window.removeEventListener('resize', handleOrientationChange)};
  }, []);

  useEffect(() => {
    setLoading(true);
    getServerInfo()
      .then((response) => {
        if (response.ok)
          response.json().then((r) => setServerInfo(r))
      })
    getUserInfo()
      .then((response) => {
        if (response.ok)
          response.json().then((r) => {
            localStorage.setItem('unseenPosts', r?.unseenPosts);
            localStorage.setItem('billDifference', r?.billDifference);
          })
      })
    getHome(year, month, type, region, sport, cancelled)
      .then((response) => {
        if (!response.ok) {
          setErrorRemote(handleErrorLoading(response));
          return {};
        }
        return response.json()
      })
      .then((response) => {
        setData(response);
        setLoading(false);
        loadScroll();
      })
      .catch((err) => console.log(err));
  }, [month, year, type, region, sport, cancelled]);

  function resets() {
    const date = new Date();
    setSpinResetIcon(true);
    setTimeout(function () {
      setSpinResetIcon(false);
    }, 1000);
    setMonth("0");
    setYear(date.getFullYear());
    setType( Theme === "PGP" ? "K" : (date.getMonth() + 1 < 3 || date.getMonth() + 1 > 10) ? "*" : "O");
    setSport("0");
    setRegion("");
    setCancelled("0");
    sessionStorage.setItem('homeScroll', 0);
  }

  const TableDataHiddenLink = ({children, id, title, center=false, strikethrough=false}) => {
    let alignment;
    if (children == null || children === "") {
      children = " ";
      alignment = "center";
    } else if (center) {
      alignment = "center";
    } else {
      alignment = "left";
    }
    return (
      <td onClick={saveScroll} align={alignment} title={title}><a href={`/akce/${id}`} className={strikethrough ? "strike-link" : "hide-link"}>{children}</a></td>);
  }

  const EventTypeIcon = ({orisId, small=false, large=false}) => {
    if (orisId != null) {
      return <a href={ORIS_URL + "Zavod?id=" + orisId} target="_blank" rel="noreferrer">
        <img
          src={selectLogoOris(true)}
          height={small ? "14" : "20"}
          className="d-inline-block align-top"
          alt="ORIS"
        />
      </a>;
    } else {
      return <img
        src={selectIconClub()}
        height={large ? "28" : (small ? "14" : "20")}
        className="d-inline-block align-top"
        alt={Theme}
      />;
    }
  }

  const EventTable = () => {
    if (loading)
      return <LoadingIcon />;
    return isMobile ? <MobileTable events={data?.events} today={data?.today}/> : <DesktopTable events={data?.events} today={data?.today}/>;
  }

  if (errorRemote !== "")
    return <ErrorAlertFullscreen error={errorRemote}/>

  setBrowserTabText();
  return (
    <PageContainer message={serverInfo?.message} info={serverInfo?.info}>
      <h1>Kalendář akcí</h1>
      <Filters/>
      <EventTable />
    </PageContainer>
  );

  function Filters() {
    const date = new Date();
    return (
      <Row className="align-items-end">
        <Col><Row className="mb-1">
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectArray label={"Rok"} size={"sm"} value={year} setValue={setYearRotateMonth} options={HomeYearOptions()} className={"mb-2"} controlId={"year"} />
          </Col>
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectDict label={"Měsíc"} size={"sm"} value={month} setValue={setMonth} options={year === date.getFullYear() ? MonthOptionsExtendedCurrent : MonthOptionsExtended} className={"mb-2"} controlId={"month"} />
          </Col>
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectDict label={"Oblast"} size={"sm"} value={region} setValue={setRegion} options={RegionOptions} className={"mb-2"} controlId={"region"} />
          </Col>
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectDict label={"Typ akce"} size={"sm"} value={type} setValue={setType} options={Theme === "PGP" ? EventTypeOptionsAlt : EventTypeOptions} className={"mb-2"} controlId={"type"} />
          </Col>
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectDict label={"Sport"} size={"sm"} value={sport} setValue={setSport} options={SportTypeOptions} className={"mb-2"} controlId={"sport"} />
          </Col>
          <Col xs={6} sm={4} md={4} lg={2}>
            <FormSelectDict label={"Zrušené" + (userData?.administrate ? " a skryté" : "")} size={"sm"} value={cancelled} setValue={setCancelled} options={CancelledOptions} className={"mb-2"} controlId={"cancelled"} />
          </Col>
        </Row></Col>
        <Col xs="auto" className="mb-2 me-2 ps-0 pe-2 pb-1">
          {spinResetIcon ? <FontAwesomeIcon icon={faRotate} onClick={resets} spin/> :
            <FontAwesomeIcon icon={faRotate} onClick={resets} className="pointing"/>
          }
        </Col>
      </Row>
    )
  }

  function setYearRotateMonth(newYear) {
    let date = new Date();
    let currentYear = date.getFullYear();
    if (year === currentYear && Number(newYear) !== currentYear)
      setMonth(1);
    if (year !== currentYear && Number(newYear) === currentYear)
      setMonth(0);
    setYear(Number(newYear));
  }

  function saveScroll() {
    const scrollingElement = (document.scrollingElement || document.body);
    sessionStorage.setItem('homeScroll', scrollingElement.scrollTop);
  }

  function loadScroll() {
    const scrollingElement = (document.scrollingElement || document.body);
    const lastScroll = sessionStorage.getItem('homeScroll') ? sessionStorage.getItem('homeScroll') : 0;
    setTimeout(function () {
      scrollingElement.scrollTop = lastScroll;
    }, 60);
  }

  function MobileTable({events, today}) {
    return (
      <Table striped bordered responsive>
        <thead>
        <tr>
          <th>Dat.</th>
          <th>Název</th>
          <th>Obl.</th>
          <th>Dis.</th>
          <th>Př.</th>
        </tr>
        </thead>
        <tbody>
        <MobileTableContent />
        </tbody>
      </Table>
    );

    function MobileTableContent() {
      const MobileTableRow = ({event, style}) => {
        return (
          <tr className={selectEnrollVariant(event, "table-")} style={style}>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{parseDateWithoutYear(event.date)}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.title}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.region}{data?.level_colors && <LevelIndicator event={event} lineBreak={true}/>}</TableDataHiddenLink>
            <td align="center">
              <p className="mb-1">{event.discipline}</p>
              <EventTypeIcon orisId={event.oris_id} small={true}/>
            </td>
            <td align="center">
              {event.club_entries !== 0 && <div className="mb-1">
                <Link to={`/akce/${event.id}/prihlasky`} className="table-link no-decoration align-center">{event.club_entries}</Link>
              </div>}
              <TermIndicator event={event} vertical={true} />
            </td>
          </tr>
        )
      }

      const rows = [];
      let prev = -1;
      for (const i in events) {
        const element = events[i];
        let cmp = (element.date < today ? -1 : (element.date > today ? 1 : 0));
        const style = cmp !== prev ? {borderTop: "2px solid", borderTopColor: "orange"} : {};
        rows.push(<MobileTableRow event={element} key={element.id} style={style}/>);
        prev = cmp;
      }
      return rows;
    }
  }

  function DesktopTable({events, today}) {
    return (
      <Table striped bordered hover={labels.CondElemTableHover} responsive>
        <thead>
        <tr>
          <th>Datum</th>
          <th>Název</th>
          {settingsView ? <>
            <th title="Oblast">Obl.</th>
            <th></th>
            <th title="ubytování ne/možné/povinné">U</th>
            <th title="doprava ne/možné/povinné">D</th>
            <th title="doprava rozdělena">R</th>
            <th title="termín předběžný/manuální/1/2/3">T?</th>
          </> : <>
            {data?.level_colors && <th></th>}
            <th title="Oblast">Obl.</th>
            <th title="Disciplína">Dis.</th>
            <th title="Pořadatel">Poř.</th>
          </>}
          <th title="Počet přihlášených">Př.</th>
          <th title={"ORIS/" + HomeClub}>Typ</th>
          {userData?.edit_events ? <th style={{justifyItems: "center"}}>
            <FormCheckbox value={settingsView} setValue={setSettingsView} type={"switch"} condensed={true} controlId={"settingsView"} tooltip={"Zobrazení nastavení akcí"}/>
          </th> : <th title="Termín přihlášek/startovky/výsledky" style={{textAlign: "center"}}>
            <FontAwesomeIcon icon={faInfo}/>
          </th>}
        </tr>
        </thead>
        <tbody>
        <DesktopTableContent />
        </tbody>
      </Table>
    );

    function DesktopTableContent() {
      const EventTypeIconCell = ({orisId}) => {
        if (orisId != null) {
          return <td align="center">
            <EventTypeIcon orisId={orisId} />
          </td>;
        } else {
          const isLarger = Theme === "PGP";
          return <td align="center" className={isLarger ? `pt-1 pb-1` : ""}>
            <EventTypeIcon orisId={orisId} large={isLarger} />
          </td>;
        }
      }

      const DesktopTableRow = ({event, style}) => {
        return (
          <tr id={"eventrow" + event.id} className={selectEnrollVariant(event, "table-")} style={style}>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{parseDate(event.date)}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.title}</TableDataHiddenLink>
            {data?.level_colors && <TableDataHiddenLink id={event.id} center={true}><LevelIndicator event={event} /></TableDataHiddenLink>}
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.region}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} title={DisciplineAllOptions[event.discipline]} strikethrough={event.cancelled}>{event.discipline}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.organiser ? event.organiser : Theme}</TableDataHiddenLink>
            <td align="center">
              {event.club_entries !== 0 && (event.results ?
                <Link to={ORIS_URL + "Vysledky?id=" + event.oris_id + "&club=" + HomeClub} target="_blank" className="table-link no-decoration">{event.club_entries}</Link> :
                <Link to={`/akce/${event.id}/prihlasky`} className="table-link no-decoration">{event.club_entries}</Link>)}
            </td>
            <EventTypeIconCell orisId={event.oris_id}/>
            <td style={{paddingRight: "2px"}}><TermIndicator event={event} /></td>
          </tr>
        )
      }

      const TernaryIcon = ({subject}) => {
        if (subject === 1)
          return <FontAwesomeIcon icon={faCheckDouble} />;
        if (subject === 0)
          return <FontAwesomeIcon icon={faCheck} />;
        return <FontAwesomeIcon icon={faXmark} color="gray" />;
      }

      const BinaryIcon = ({condition, highlight}) => {
        if (condition)
          return <FontAwesomeIcon icon={faCheck} />;
        if (highlight)
          return <FontAwesomeIcon icon={faXmark} color="red" />;
        return <FontAwesomeIcon icon={faXmark} />;
      }

      const DesktopTableSettingsRow = ({event, style}) => {
        return (
          <tr id={"eventrow" + event.id} className={selectEnrollVariant(event, "table-")} style={style}>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{parseDate(event.date)}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.title}</TableDataHiddenLink>
            <TableDataHiddenLink id={event.id} strikethrough={event.cancelled}>{event.organiser}</TableDataHiddenLink>
            <td className="td-w-icon">{!event.cancelled && <Link to={`/akce/${event.id}/upravit`}><FontAwesomeIcon icon={faPencil}/></Link>}</td>
            <td align="center"><TernaryIcon subject={event?.accommodation}/></td>
            <td align="center"><TernaryIcon subject={event?.transport}/></td>
            <td align="center">
              {event?.transport > -1 ?
                <Link to={`/akce/${event.id}/doprava/detail`} className="hide-link"><BinaryIcon condition={event?.transport_handled} highlight={event?.transport_requests > 0}/></Link> :
                <TernaryIcon subject={-1}/>
              }
            </td>
            <td align="center" className="tapis-75" style={{whiteSpace: "nowrap"}}>
              {event.term_preliminary ? "P" : "-"}{event.term_manual ? "M" : "-"}{event.term_first ? "1" : "-"}{event.term_second ? "2" : "-"}{event.term_third ? "3" : "-"}
            </td>
            <td align="center">
              {event.club_entries !== 0 && (event.results ?
                <Link to={ORIS_URL + "Vysledky?id=" + event.oris_id + "&club=" + HomeClub} target="_blank"
                      className="table-link no-decoration">{event.club_entries}</Link> :
                <Link to={`/akce/${event.id}/klub`} className="table-link no-decoration">{event.club_entries}</Link>)}
            </td>
            <EventTypeIconCell orisId={event.oris_id}/>
            <td style={{paddingRight: "2px"}}><TermIndicator event={event} /></td>
          </tr>
        )
      }

      const rows = [];
      let prev = -1;
      for (const i in events) {
        const element = events[i];
        let cmp = (element.date < today ? -1 : (element.date > today ? 1 : 0));
        const style = cmp !== prev ? {borderTop: "2px solid", borderTopColor: "orange"} : {};
        if (settingsView)
          rows.push(<DesktopTableSettingsRow event={element} key={element.id} style={style}/>);
        else
          rows.push(<DesktopTableRow event={element} key={element.id} style={style}/>);
        prev = cmp;
      }
      return rows;
    }
  }
}

export default Home;