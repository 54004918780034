import Button from "react-bootstrap/esm/Button";
import React, { useState } from 'react';
import {updateUsersCsv} from "../../dao";
import ErrorAlert from "../../components/overlays/ErrorAlert";
import SuccessAlert from "../../components/overlays/SuccessAlert";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronLeft, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import PageContainer from "../../layout/PageContainer";
import Modal from "react-bootstrap/Modal";
import {handleErrorSubmit, setBrowserTabText, verifyFileIsCsv} from "../../helpers/Functions";
import LoadingCard from "../../components/overlays/LoadingCard";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {HomeClub} from "../../helpers/Constants";
import ModalContentAccount from "../../components/parts/ModalContentAccount";


const EditUsersCsv = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [added, setAdded] = useState(false);
  const [data, setData] = useState([]);
  const [generalHelp, setGeneralHelp] = useState(false);

  const changeHandler = (event) => {
    if(verifyFileIsCsv(event.target.files[0])){
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    }else{
      setError("Špatný typ souboru");
    }
  };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append('File', selectedFile);
    setProcessing(true);
    updateUsersCsv(formData)
      .then((response) => {
        if(response.ok){
          response.json().then(
            (result) => {
              setSuccess("Úspěšně nahráno");
              setData(result)
              setAdded(true);
          });
        }else{
          setError(handleErrorSubmit(response, "Nezdařilo se nahrát"));
        }
        setProcessing(false);
      });
  };

  function AddedAccounts () {
    const rows = [];
    for(let i in data.data){
      const person = data.data[i];
      rows.push(
        <tr key={person.id}>
          <td>{person.id}</td>
          <td>{person.reg_number}</td>
          <td>{person.full_name}</td>
        </tr>
      );
    }
    return(
      <Table bordered>
        <tbody>
        {rows}
        </tbody>
      </Table>
    )
  }

  if (processing)
    return <LoadingCard />;

  setBrowserTabText('Nahrát uživatele');
  return (
    <PageContainer>
      <h1>Úprava uživatelů pomocí csv</h1>
      <br/>
      {added ?
        <>
          <h3>Upravené účty</h3>
          <AddedAccounts />
          <Row className="text-end"><Link to="/admin"><Button variant="secondary"><FontAwesomeIcon icon={faCircleChevronLeft}/> Administrace</Button></Link></Row>
        </> :
      <Row className="align-items-end">
        <Col>
          <p><b>Pořadí sloupců</b> je následující:</p>
          <ol type="A">
            <li>reg. číslo (např. {HomeClub}0123), slouží pro identifikaci účtu</li>
            <li>jméno</li>
            <li>příjmení</li>
            <li>dat. narození, jako dd.mm.yyyy nebo yyyy-mm-dd</li>
            <li>mail1 (může být prázdné, ale uživateli nebudou fungovat veškeré mailové služby)</li>
            <li>mail2</li>
            <li>telefon</li>
            <li>účet – A/B/C</li>
            <li>role – U/T/H/X/S <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
            <li>členství – R/P/O <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
            <li>skupina – DO/SZ/MZ/DE/AD/PD/VE/-- <FontAwesomeIcon className="pointing" icon={faQuestionCircle} onClick={() => setGeneralHelp(true)}/></li>
          </ol>
          <p>
            Tabulka <b>nesmí obsahovat hlavičku</b><br/>
            Validita záznamů <b>není kontrolována</b>
          </p>
          <p>Sloupec A je povinný, ostatní sloupce mohou být prázné (pokud se daná hodnota nemění) nebo mohou obsahovat hodnotu (pak dojde k aktualizaci na tuto hodnotu).
            Pokud CSV obsahuje méně sloupců než 11, pak jsou chybějící sloupce chápány jako prázdné.</p>
        </Col>
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <Button className="mb-2" variant="outline-primary" onClick={() => document.getElementById('csvFileSelect').click()}>Vyber CSV</Button>
                <input className="form-control d-none" id='csvFileSelect' type="file" onChange={changeHandler}></input>
              </Col>
              {isSelected && <Col><b>{selectedFile.name}</b><br/>velikost: {selectedFile.size} B</Col>}
            </Row>
          </Form.Group>
          <div>
            <Button className="btn btn-primary" disabled={!isSelected} onClick={handleSubmission}>Nahrát</Button>
          </div>
        </Col>
      </Row>}
      <ErrorAlert error={error} handleClose={()=>setError("")}/>
      <SuccessAlert title={success} body={"Počet úspěchů: " + data.count} handleClose={()=>setSuccess("")}/>
      <Modal show={generalHelp} onHide={()=>setGeneralHelp(false)}>
        <ModalContentAccount />
      </Modal>
    </PageContainer>
  )
};

export default EditUsersCsv;
