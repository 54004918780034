import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import React from "react";


const ModalContentAccount = ({options}) => {
  const roleRows = [];
  for (const short in options?.roles) {
    const item = options.roles[short];
    roleRows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  const membershipRows = [];
  for (const short in options?.memberships) {
    const item = options.memberships[short];
    membershipRows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  const groupRows = [];
  for (const short in options?.groups) {
    const item = options.groups[short];
    groupRows.push(<tr>
      <td className="td-w-10">{short}</td>
      <td>{item}</td>
    </tr>)
  }
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Nápověda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Role</Modal.Title>
        <Table bordered>
          <tbody>
          {roleRows}
          </tbody>
        </Table>
        <Modal.Title>Členství</Modal.Title>
        <Table bordered>
          <tbody>
          {membershipRows}
          </tbody>
        </Table>
        <Modal.Title>Skupiny</Modal.Title>
        <Table bordered>
          <tbody>
          {groupRows}
          </tbody>
        </Table>
      </Modal.Body>
    </>
  )
}

export default ModalContentAccount;