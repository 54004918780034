// PostEditor.js
import React, { useState, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PostEditor = ({ initialContent, onSave, buttonText="Uložit", buttonDisabled=false, allowIndent=true }) => {
  const [content, setContent] = useState(initialContent || '');

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSave = () => {
    onSave(content);
  };

  // Dynamically adjust modules and formats based on allowIndent
  const modules = useMemo(() => {
    const toolbar = [
      [{ 'header': '1' }, { 'header': '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ];

    if (allowIndent) {
      toolbar[3].push({ 'indent': '-1' }, { 'indent': '+1' });
    }

    return { toolbar };
  }, [allowIndent]);

  const formats = useMemo(() => {
    const baseFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike',
      'list', 'bullet',
      'color', 'background', 'link'
    ];

    if (allowIndent) {
      baseFormats.push('indent');
    }

    return baseFormats;
  }, [allowIndent]);

  return (
    <div>
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
      <Row>
        <Col className="text-end">
          <Button className="mt-3" onClick={handleSave} disabled={buttonDisabled}><b>{buttonText}</b></Button>
        </Col>
      </Row>
      {allowIndent && <p><i>Pozn.: Na webu nelze pro odsazení textu používat mezery, používej tlačítko pro odsazení řádky (vpravo vedle seznamů)!</i></p>}
    </div>
  );
};

export default PostEditor;
